/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'gatsby';

import './Article.scss';
import './SectionBlock.scss';

const SectionBlock = ({
  img,
  text,
  imgFirst = false,
  imgFull = false,
  noPadding = false,
  customClass = '',
  cta = false,
}) => {
  const renderColText = (textArray) => {
    return (
      // eslint-disable-next-line no-nested-ternary
      <div key="colText" className={`col-lg-${img ? (imgFull ? 12 : 7) : 12}`}>
        {textArray.map((textPart) => {
          const { title, description } = textPart;
          return (
            <div key={title && description.substr(0, 15)} className="th-article-text">
              {title && (
                <h3
                  className="th-article-content__header th-article-content__header--decorated"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}
              <div className="th-article-content__paragraph" dangerouslySetInnerHTML={{ __html: description }} />
              {cta && (
                <div className="th-section-block-cta">
                  <Link className="th-section-block-cta__anchor" to={cta.link}>
                    {cta.text}
                  </Link>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const colText = renderColText(text);

  const colImg = img && (
    <div key="colImg" className={`col-lg-${imgFull ? 12 : 5}`}>
      <div className="th-article-content__image">
        <img src={img} alt="iot example" />
      </div>
    </div>
  );

  return (
    <div className={`th-article-content${customClass ? ` ${customClass}` : ''}`}>
      <div className={`th-article-main th-article-main${noPadding ? '--no-padding' : ''}`}>
        <div className="container">
          <div className="row no-gutters">{!imgFirst ? [colText, colImg] : [colImg, colText]}</div>
        </div>
      </div>
    </div>
  );
};

export default SectionBlock;
