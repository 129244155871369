import React from 'react';

import SectionBlock from '../components/Block/SectionBlock';

const sections = [
  {
    img: false,
    text: [
      {
        title: 'Customer',
        description:
          '<p>JOHAN Sports is a sports tech company located in the Netherlands. JOHAN sports delivers an accurate ' +
          'GPS Tracking Systems for sports teams, thus improving the physical performance of players and preventing injuries.</p>',
      },
      {
        title: 'Challenge',
        description:
          '<ul>' +
          '<li>New GPS personal trackers</li>' +
          '<li>Monitoring of new real-time data</li>' +
          '<li>Integration of new trackers with the existing monitoring system</li>' +
          '</ul>',
      },
      {
        title: 'Solution',
        description:
          '<ul>' +
          '<li>SolveQ team responsibility:' +
          '<ul>' +
          '<li>the architecture of the application</li>' +
          '<li>implementation</li>' +
          '<li>integration of new devices with the new firmware</li>' +
          '</ul></li>' +
          '<li>React Native Framework as a base for application</li>' +
          '</ul>',
      },
      {
        title: 'Results ',
        description:
          '<ul>' +
          '<li>The valuable base for training adjustment preventing contusions and overload</li>' +
          '<li>The wider market for JOHAN Sports - functionality applicable in various sports like football, field hockey, and rugby.</li>' +
          '<li>Responsive application working on smartphones and tablets, both on Android and IOS</li>' +
          '<li>The user-friendly interface providing detailed information on sportsman performance like a heartbeat, ' +
          'sportsman accelerations during training, kilometers ran</li>' +
          '</ul>',
      },
    ],
  },
];

const JohanPage = () => {
  return (
    <>
      <div className="th-hero-page">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="th-hero-content">
                <h1 className="th-hero-content__header">JOHAN Live Tracking App</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <SectionBlock imgFull imgFirst {...sections[0]} />
    </>
  );
};

export default JohanPage;
